/* eslint-disable no-warning-comments */
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import {
    waveLgMediaQuery,
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../styles/waveMediaQueries';
import BookingsCard from './dashboardCards/BookingsCard';
import UserProfileCardQuery from './dashboardCards/UserProfileCardQuery';
import BadgesCardQuery from './dashboardCards/BadgesCardQuery';
import RentalcarCard from './dashboardCards/RentalcarCard/RentalcarCard';
import PremiumCard from './dashboardCards/PremiumCard/PremiumCard';
import ReviewCardQuery from './dashboardCards/ReviewCard/ReviewCardQuery';
import VoucherCardQuery from './dashboardCards/VoucherCard/VoucherCardQuery';
import MemberCard from './dashboardCards/MemberCard';

const cardStyle = {
    borderRadius: 8,
    '&:empty': {
        display: 'none',
    },
};

const mdSideColumnWidth = 222;
const mdCenterColumnWidth = 468;
const lgSideColumnWidth = 282;
const lgCenterColumnWidth = 588;
const mdMaximalAllowedFullHeight = 940;

const UserProfileCardContainer = glamorous.div({
    ...cardStyle,
    backgroundColor: options.colors.white,
    color: options.colors.black,
    flexBasis: '100%',
    [waveMdMediaQuery]: {
        flexBasis: 'auto',
        width: mdSideColumnWidth,
        order: 1,
    },
    [waveLgMediaQuery]: {
        width: lgSideColumnWidth,
    },
});

const BookingsCardContainer = glamorous.div({
    ...cardStyle,
    flexBasis: '100%',
    [waveMdMediaQuery]: {
        flexBasis: 'auto',
        width: mdCenterColumnWidth,
        order: 3,
    },
    [waveLgMediaQuery]: {
        width: lgCenterColumnWidth,
    },
});

const RentalCarCardContainer = glamorous.div({
    ...cardStyle,
    backgroundColor: options.colors.white,
    flexBasis: '100%',
    [waveMdMediaQuery]: {
        flexBasis: 'auto',
        width: mdCenterColumnWidth,
        order: 4,
    },
    [waveLgMediaQuery]: {
        width: lgCenterColumnWidth,
    },
});

const VoucherCardContainer = glamorous.div({
    ...cardStyle,
    overflow: 'hidden',
    [waveMdMediaQuery]: {
        width: mdSideColumnWidth,
        order: 6,
    },
    [waveLgMediaQuery]: {
        width: lgSideColumnWidth,
    },
});

const PremiumCardContainer = glamorous.div({
    ...cardStyle,
    overflow: 'hidden',
    [waveMdMediaQuery]: {
        width: mdSideColumnWidth,
        order: 7,
    },
    [waveLgMediaQuery]: {
        width: lgSideColumnWidth,
    },
});

const BadgesCardContainer = glamorous.div({
    ...cardStyle,
    backgroundColor: options.colors.white,
    color: options.colors.black,
    flexBasis: '100%',
    [waveSmMediaQuery]: {
        flexBasis: 0,
        flexGrow: 1,
    },
    [waveMdMediaQuery]: {
        flexBasis: 'auto',
        width: mdSideColumnWidth,
        order: 2,
    },
    [waveLgMediaQuery]: {
        width: lgSideColumnWidth,
    },
});

const ReviewCardContainer = glamorous.div({
    ...cardStyle,
    flexBasis: '100%',
    [waveSmMediaQuery]: {
        flexBasis: 0,
        flexGrow: 1,
    },
    [waveMdMediaQuery]: {
        flexBasis: 'auto',
        width: mdCenterColumnWidth,
        order: 5,
    },
    [waveLgMediaQuery]: {
        width: lgCenterColumnWidth,
    },
});

const DashboardContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontFamily: options.fonts.default,
    columnGap: options.space.m,
    rowGap: options.space.xl,
    margin: `${options.space['3xl']}px ${options.space.l}px`,
    [waveSmMediaQuery]: {
        marginLeft: options.space.xl,
        marginRight: options.space.xl,
    },
    [waveMdMediaQuery]: {
        columnGap: options.space.xl,
        flexDirection: 'column',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 960,
        maxHeight: mdMaximalAllowedFullHeight,
    },
    [waveLgMediaQuery]: {
        width: 1200,
    },
});

const BadgesColumn = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space.xl,
    flexBasis: '100%',
    [waveMdMediaQuery]: {
        flexBasis: 'auto',
        width: mdSideColumnWidth,
        order: 8,
    },
});

const MemberCardContainer = glamorous.div({
    ...cardStyle,
    overflow: 'hidden',
    [waveMdMediaQuery]: {
        width: mdSideColumnWidth,
        order: 6,
    },
    [waveLgMediaQuery]: {
        width: lgSideColumnWidth,
    },
});

function Dashboard() {
    return h(DashboardContainer, [
        h(UserProfileCardContainer, [h(UserProfileCardQuery)]),
        h(BookingsCardContainer, [h(BookingsCard)]),
        h(RentalCarCardContainer, [h(RentalcarCard)]),
        h(BadgesColumn, [
            h(VoucherCardContainer, [h(VoucherCardQuery)]),
            h(MemberCardContainer, [h(MemberCard)]),
            h(PremiumCardContainer, [h(PremiumCard)]),
        ]),
        h(ReviewCardContainer, [h(ReviewCardQuery)]),
        h(BadgesCardContainer, [h(BadgesCardQuery)]),
    ]);
}

export default Dashboard;
